import { useEffect, useState } from "react";
import { Button, Col, Flex, Modal, Row } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  getProcessesByProduct,
  createProductProcess,
  updateProductProcess,
} from "../../../../servicesHTTP";
import { severalStore } from "../../../../stores";
import { Table } from "../../../../components";
import { messageSweetalert } from "../../../../utilities";
import CreateEdit from "./CreateEdit";

const initialData: any = {
  id: 0,
  implement_measures_mitigate_environmental_impact_id: 0,
  implement_bpm_bpa_bpg_id: 0,
  name: "",
  product_id: 0,
  processes_standardized: 0,
  monitor_evaluate_processes: 0,
  infrastructure_property_id: 0,
  how_commercial_logistical_activities_processes_organized_id: 0,
  product_type_id: 0,
  key: 0,
  implement_measures_mitigate_environmental_impact: null,
  implement_bpm_bpa_bpg: null,
  processes_types: [],
  processes_description: [],
  processes_infrastructure_types: [],
  processes_infrastructure_description: [],
  infrastructure_property: null,
  how_commercial_logistical_activities_processes_organized: null,
};

function Home({ open, handleClose, product, productType }: any) {
  const { setShowLoading }: any = severalStore();
  const [processes, setProcesses] = useState<any>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [columns] = useState<any>([
    {
      title:
        "¿Pone en práctica medidas de mitigación del impacto ambiental de la actividad económica?",
      dataIndex: "implement_measures_mitigate_environmental_impact_id",
      key: "implement_measures_mitigate_environmental_impact_id",
      render: (text: any, record: any, index: any) => {
        return `${
          record.implement_measures_mitigate_environmental_impact
            ? record.implement_measures_mitigate_environmental_impact.name
            : ""
        }`;
      },
    },
    {
      title:
        "¿Implementa buenas prácticas (BPM,BPA, BPG,etc) para la actividad económica?",
      dataIndex: "implement_bpm_bpa_bpg_id",
      key: "implement_bpm_bpa_bpg_id",
      render: (text: any, record: any, index: any) => {
        return `${
          record.implement_bpm_bpa_bpg ? record.implement_bpm_bpa_bpg.name : ""
        }`;
      },
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title:
        "¿Sus procesos estan estandarizados y protocolizados en documentos y/o manuales?",
      dataIndex: "processes_standardized",
      key: "processes_standardized",
      render: (text: any, record: any, index: any) => {
        return `${String(record.processes_standardized) === "2" ? "Si" : "No"}`;
      },
    },
    {
      title: "¿Hace seguimiento y evaluación de sus procesos?",
      dataIndex: "monitor_evaluate_processes",
      key: "monitor_evaluate_processes",
      render: (text: any, record: any, index: any) => {
        return `${
          String(record.monitor_evaluate_processes) === "2" ? "Si" : "No"
        }`;
      },
    },
    {
      title: "La infraestructura que usa es",
      dataIndex: "infrastructure_property_id",
      key: "infrastructure_property_id",
      render: (text: any, record: any, index: any) => {
        return `${
          record.infrastructure_property
            ? record.infrastructure_property.name
            : ""
        }`;
      },
    },
    {
      title:
        "¿Cómo se organizan las actividades o procesos comerciales y logísticos de la actividad económica?",
      dataIndex: "how_commercial_logistical_activities_processes_organized_id",
      key: "how_commercial_logistical_activities_processes_organized_id",
      render: (text: any, record: any, index: any) => {
        return `${
          record.how_commercial_logistical_activities_processes_organized
            ? record.how_commercial_logistical_activities_processes_organized
                .name
            : ""
        }`;
      },
    },
  ]);

  const getProcesses = async (productId: any, productType: any) => {
    if (productId !== 0) {
      const result = await getProcessesByProduct(productType, productId);
      if (result.status === "ok") {
        setProcesses(result.data);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setShowLoading(true);
      await getProcesses(product.id, productType);
      setShowLoading(false);
    })();
  }, [setShowLoading, productType, product.id]);

  useEffect(() => {
    if (selected.length > 0) {
      const pre: any = processes.filter((x: any) => x.id === selected[0].id);
      if (pre) {
        setSelected(pre);
      }
    }
    // eslint-disable-next-line
  }, [processes]);

  const onChangeSelect = (newSelectedRowKeys: any, newSelectedRows: any) => {
    setSelected(newSelectedRows);
  };

  const openCreate = (selected: any[]) => {
    if (selected.length > 0) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar ningún registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const openEdit = (selected: any[]) => {
    if (selected.length > 1) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar mas de 1 registro</p>,
        "error"
      );
      return;
    }

    if (selected.length <= 0) {
      messageSweetalert(
        "Terminado",
        <p>Debe seleccionar 1 registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const save = async (data: any) => {
    if (data.new) {
      setShowLoading(true);
      const result = await createProductProcess(data);
      if (result.status === "ok") {
        messageSweetalert("Terminado", <p>Datos guardados.</p>);
        await getProcesses(product.id, productType);
        setModalOpen(false);
        setSelected([]);
      } else {
        messageSweetalert(
          "Terminado",
          <p>No se guardaron los datos.</p>,
          "error"
        );
      }
      setShowLoading(false);
    } else {
      setShowLoading(true);
      const result = await updateProductProcess(data);
      if (result.status === "ok") {
        messageSweetalert("Terminado", <p>Datos guardados.</p>);
        await getProcesses(product.id, productType);
      } else {
        messageSweetalert(
          "Terminado",
          <p>No se guardaron los datos.</p>,
          "error"
        );
      }
      setShowLoading(false);
    }
  };

  return (
    <Modal
      title={`Procesos`}
      centered
      open={open}
      okText={"Guardar"}
      onCancel={() => handleClose(false)}
      width={"70vw"}
      cancelText="Cerrar"
      okButtonProps={{
        style: {
          display: "none",
        },
      }}
    >
      <Row className="row-form">
        <Col span={23}>
          <Flex style={{ marginBottom: 10 }}>
            <Button
              type="primary"
              size="large"
              icon={<PlusOutlined />}
              style={{ marginRight: 10 }}
              disabled={selected.length > 0}
              onClick={() => openCreate(selected)}
            >
              Agregar
            </Button>
            <Button
              type="primary"
              size="large"
              icon={<EditOutlined />}
              disabled={selected.length <= 0}
              onClick={() => openEdit(selected)}
            >
              Editar
            </Button>
          </Flex>
          <Table
            dataSource={processes || []}
            columns={columns}
            bordered
            scroll={{ x: "1000" }}
            onChangeSelect={onChangeSelect}
          />
        </Col>
      </Row>

      {modalOpen && (
        <CreateEdit
          open={modalOpen}
          handleClose={setModalOpen}
          data={
            selected.length > 0
              ? selected[0]
              : {
                  ...initialData,
                  product_id: product.id,
                  product_type_id: productType,
                  new: true,
                }
          }
          save={save}
        />
      )}
    </Modal>
  );
}

export default Home;
