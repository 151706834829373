import { Col, Form, Row } from "antd";
import React from "react";
import { severalStore } from "../../../../stores";
import { Select } from "../../../../components";

function EnvironmentalGoodPractices({ handleChange, record }: any) {
  const { processMitigationMeasure, processImplementGoodPractice } =
    severalStore();
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={
              "¿Pone en práctica medidas de mitigación del impacto ambiental de la actividad económica?"
            }
          >
            <Select
              name={"implement_measures_mitigate_environmental_impact_id"}
              value={record.implement_measures_mitigate_environmental_impact_id}
              onChange={handleChange}
              placeholder="Seleccionar"
              option0={true}
              options={processMitigationMeasure.map((y: any) => ({
                value: y.id,
                label: y.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={
              "¿Implementa buenas prácticas (BPM,BPA, BPG,etc) para la actividad económica?"
            }
          >
            <Select
              name={"implement_bpm_bpa_bpg_id"}
              value={record.implement_bpm_bpa_bpg_id}
              onChange={handleChange}
              placeholder="Seleccionar"
              option0={true}
              options={processImplementGoodPractice.map((y: any) => ({
                value: y.id,
                label: y.name,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default EnvironmentalGoodPractices;
