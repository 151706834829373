export const routes = {
  home: "/home",
  characterization: "/characterization",
  diagnostic: "/diagnostic",
  sarlaft: "/sarlaft",
  sarlaftHelpCenter: "/sarlaft-help-center",
  economicActivity: "/economic-activity",
  administration: "/administration",
};

export const applicationModules = {
  home: 0,
  characterization: 1,
  diagnostic: 2,
  sarlaft: 3,
  sarlaftHelpCenter: 4,
  economicActivity: 5,
  adminitration: 6,
};

export const optionsYesNo = [
  {
    value: 2,
    label: "SÍ",
  },
  {
    value: 1,
    label: "NO",
  },
];

export const graficRadarPalletteColors = [
  {
    backgroundColor: "rgba(220,248,248,0.6)",
    borderColor: "#3dd6d6",
    pointBackgroundColor: "#3dd6d6",
    pointHoverBorderColor: "#3dd6d6",
  },
  {
    backgroundColor: "rgba(83,109,224,0.6)",
    borderColor: "#539AE0",
    pointBackgroundColor: "#539AE0",
    pointHoverBorderColor: "#539AE0",
  },
  {
    backgroundColor: "rgba(241,196,15,0.6)",
    borderColor: "#f39c12",
    pointBackgroundColor: "#f39c12",
    pointHoverBorderColor: "#f39c12",
  },
  {
    backgroundColor: "rgba(20,143,119,0.6)",
    borderColor: "#117a65",
    pointBackgroundColor: "#117a65",
    pointHoverBorderColor: "#117a65",
  },
  {
    backgroundColor: "rgba(149,117,205,0.6)",
    borderColor: "#7986cb",
    pointBackgroundColor: "#7986cb",
    pointHoverBorderColor: "#7986cb",
  },
  {
    backgroundColor: "rgba(0,255,0,0.6)",
    borderColor: "#00CC00",
    pointBackgroundColor: "#00CC00",
    pointHoverBorderColor: "#00CC00",
  },
  {
    backgroundColor: "rgba(241,148,138 ,0.6)",
    borderColor: "#ec7063",
    pointBackgroundColor: "#ec7063",
    pointHoverBorderColor: "#ec7063",
  },
  {
    backgroundColor: "rgba(255,245,157,0.6)",
    borderColor: "#ffee58",
    pointBackgroundColor: "#ffee58",
    pointHoverBorderColor: "#ffee58",
  },
];
