import { Col, Form, Input, Row } from "antd";
import ProcessesTypes from "./processesTypes/Home";
import ProcessesDescription from "./processesDescription/Home";
import { optionsYesNo } from "../../../../constants";
import { Select } from "../../../../components";

function ProcessCharacterization({ handleChange, record }: any) {
  
  return (
    <>
      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label={"Nombre Proceso"}>
            <Input
              placeholder=""
              name={"name"}
              value={record.name}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}></Col>
      </Row>

      <Row className="row-form">
        <Col span={24}>
          <ProcessesTypes handleChange={handleChange} record={record} />
        </Col>
      </Row>

      <Row className="row-form">
        <Col span={24}>
          <ProcessesDescription handleChange={handleChange} record={record} />
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item
            label={
              "¿Sus procesos estan estandarizados y protocolizados en documentos y/o manuales?"
            }
          >
            <Select
              name={"processes_standardized"}
              value={record.processes_standardized}
              onChange={handleChange}
              placeholder="Seleccionar"
              option0={true}
              options={optionsYesNo}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11} xl={11}>
          <Form.Item label={"¿Hace seguimiento y evaluación de sus procesos?"}>
            <Select
              name={"monitor_evaluate_processes"}
              value={record.monitor_evaluate_processes}
              onChange={handleChange}
              placeholder="Seleccionar"
              option0={true}
              options={optionsYesNo}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default ProcessCharacterization;
