import React from "react";
import { severalStore } from "../../../../stores";
import { Col, Form, Row } from "antd";
import { Select } from "../../../../components";

function Marketing({ handleChange, record }: any) {
  const { processLogisticalActivityOrganized } = severalStore();
  return (
    <Row className="row-form">
      <Col xs={24} md={11} xl={11}>
        <Form.Item
          label={
            "¿Cómo se organizan las actividades o procesos comerciales y logísticos de la actividad económica?"
          }
        >
          <Select
            name={"how_commercial_logistical_activities_processes_organized_id"}
            value={
              record.how_commercial_logistical_activities_processes_organized_id
            }
            onChange={handleChange}
            placeholder="Seleccionar"
            option0={true}
            options={processLogisticalActivityOrganized.map((y: any) => ({
              value: y.id,
              label: y.name,
            }))}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default Marketing;
