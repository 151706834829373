import React from "react";
import { Accordion } from "../../../../components";
import InfrastructureCharacterization from "./InfrastructureCharacterization";
import Marketing from "./Marketing";

function InfrastructureAccordion({ handleChange, record }: any) {
  return (
    <Accordion
      size={"large"}
      items={[
        {
          key: "1",
          label: "Caracterización de la Infraestructura",
          children: (
            <InfrastructureCharacterization
              handleChange={handleChange}
              record={record}
            />
          ),
        },
        {
          key: "2",
          label: "Comercialización",
          children: <Marketing handleChange={handleChange} record={record} />,
        },
      ]}
    />
  );
}

export default InfrastructureAccordion;
