import React from "react";
import { Col, Form, Row } from "antd";
import ProcessesInfrastructureTypes from "./processesInfrastructureTypes/Home";
import ProcessesInfrastructureDestription from "./processesInfrastructureDescription/Home";
import { Select } from "../../../../components";
import { severalStore } from "../../../../stores";

function InfrastructureCharacterization({ handleChange, record }: any) {
  const { processInfrastructureProperty } = severalStore();
  return (
    <>
      <Row className="row-form">
        <Col span={24}>
          <ProcessesInfrastructureTypes
            handleChange={handleChange}
            record={record}
          />
        </Col>
      </Row>

      <Row className="row-form">
        <Col span={24}>
          <ProcessesInfrastructureDestription
            handleChange={handleChange}
            record={record}
          />
        </Col>
      </Row>

      <Row className="row-form">
        <Col xs={24} md={11} xl={11}>
          <Form.Item label={"La infraestructura que usa es:"}>
            <Select
              name={"infrastructure_property_id"}
              value={record.infrastructure_property_id}
              onChange={handleChange}
              placeholder="Seleccionar"
              option0={true}
              options={processInfrastructureProperty.map((y: any) => ({
                value: y.id,
                label: y.name,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default InfrastructureCharacterization;
