import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Flex, Row } from "antd";

import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { severalStore, userStore } from "../../../../stores";
import { Loading, Table } from "../../../../components";
import {
  createAssociate,
  getAssociatesByForm,
  updateAssociate,
} from "../../../../servicesHTTP";
import {
  enableButtonSave,
  getNameForm,
  messageSweetalert,
} from "../../../../utilities";
import CreateEdit from "./CreateEdit";
import { applicationModules } from "../../../../constants";

const now = dayjs();
const initialData = {
  id: 0,
  active: null,
  admission_date: now.format("YYYY-MM-DD"),
  birth_date: now.format("YYYY-MM-DD"),
  cellphone_number: "",
  document_number: "",
  document_type: "CC",
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  population_group: "0",
  marital_status: "0",
  schooling_level: "0",
  associate_type_id: 2,
  gender_id: 3,
  disability: "0",
  associate_role_id: 1,
  associate_role_committee_id: 4,
  affiliation_status: "A",
  has_reincorporated_family: "N",
  other_associative_form_name: null,
  other_associative_form_nit: null,
  percentage: 0,
  auxassociativefid: "0",
  vinculation_type: 0,
  work_position_id: 0,
  ethnic_group_id: 0,
  lgbti: false,
  reincorporated: 0,
  associative_situation_id: 0,
  founding_partner: 0,
  other_vinculation: 0,
  other_vinculation_which: [],
  bank_accounts: [],
  new: true,
  percentage_progress_information: {
    percentageGeneral: 0,
    percentageAdditional: 0,
  },
  age: 0,
};

function Associates() {
  const { setShowLoading, formSelected } = severalStore();
  const { permissions } = userStore();
  const [associates, setAssociates] = useState<any>([]);
  const [selected, setSelected] = useState({ ...initialData });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [associatesColumns] = useState<any[]>([
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 200,
    },
    {
      title: "Tipo documento",
      dataIndex: "document_type_o",
      key: "document_type_o",
      width: 400,
    },
    {
      title: "Documento",
      dataIndex: "document_number",
      key: "document_number",
      width: 200,
    },
    {
      title: "Nombre",
      dataIndex: "first_name",
      key: "first_name",
      width: 200,
    },
    {
      title: "Apellido(s)",
      dataIndex: "last_name",
      key: "last_name",
      width: 200,
    },
    {
      title: "Acción",
      dataIndex: "acciones",
      key: "acciones",
      width: 200,
      render: (text: any, record: any, index: any) => {
        return (
          <Button
            type="link"
            size="large"
            icon={<EditOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => {
              setSelected({ ...record, new: false });
              setModalOpen(true);
            }}
          >
            Editar
          </Button>
        );
      },
    },
  ]);

  const getAssociates = useCallback(
    async (form: any) => {
      setShowLoading(true);
      const data = await getAssociatesByForm(form.id);
      if (data.status === "ok") {
        setAssociates(data.data.map((x: any) => ({ ...x, key: x.id })));
      }
      setShowLoading(false);
    },
    [setShowLoading]
  );

  useEffect(() => {
    getAssociates(formSelected);
  }, [formSelected, getAssociates]);

  useEffect(() => {
    if (Object.keys(selected).length > 0) {
      const pre: any = associates.find((x: any) => x.id === selected.id);
      if (pre) {
        setSelected(pre);
      }
    }
    // eslint-disable-next-line
  }, [associates]);

  const saveRecord = async (data: any) => {
    if (data.new) {
      setShowLoading(true);
      const result = await createAssociate({
        ...data,
        // @ts-ignore
        associative_form_id: formSelected.id,
      });
      if (result.status === "ok") {
        setModalOpen(false);
        messageSweetalert("Terminado", <p>Datos guardados.</p>);
        setSelected({ ...initialData });
      } else {
        messageSweetalert(
          "Error",
          <p>
            Error al crear registro. <br /> {result?.errores}
          </p>,
          "error"
        );
      }
      getAssociates(formSelected);
      setShowLoading(false);
    } else {
      setShowLoading(true);
      const result = await updateAssociate({
        ...data,
        // @ts-ignore
        associative_form_id: formSelected.id,
      });
      if (result.status === "ok") {
        messageSweetalert("Terminado", <p>Datos guardados.</p>);
      } else {
        messageSweetalert("Error", <p>Error al crear registro</p>, "error");
      }
      getAssociates(formSelected);
      setShowLoading(false);
    }
  };
  return (
    <>
      <Row>
        <Col xs={24} md={12} xl={12} xxl={12}>
          <div className="mt-4">
            <h1 className="associative-forms-list-title">
              Asociados y Asociadas de la formas asociativa
            </h1>
            {getNameForm(formSelected) !== "" && (
              <span> ({getNameForm(formSelected)})</span>
            )}
          </div>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link
                    color="inherit"
                    to="/"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    Inicio
                  </Link>
                ),
              },
              {
                title: "Forma Asociativa",
              },
              {
                title: "Asociados y Asociadas",
              },
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: 15 }}>
        <Col span={24}>
          <Flex style={{ marginBottom: 10 }}>
            {enableButtonSave(applicationModules, permissions) && (
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                style={{ marginRight: 10 }}
                onClick={() => {
                  setSelected({ ...initialData });
                  setModalOpen(true);
                }}
              >
                Crear
              </Button>
            )}
          </Flex>
          <Table
            dataSource={associates || []}
            columns={associatesColumns}
            bordered
            scroll={{ x: "1000" }}
            selection={false}
          />
        </Col>
      </Row>
      {modalOpen && (
        <CreateEdit
          open={modalOpen}
          handleClose={setModalOpen}
          data={{ ...selected }}
          setData={setSelected}
          saveRecord={saveRecord}
        />
      )}
      <Loading />
    </>
  );
}

export default Associates;
