import React, { useCallback, useEffect, useState } from "react";
import { Alert, Col, Form, Input, Modal, Row } from "antd";
import { Select } from "../../../components";
import { severalStore } from "../../../stores";
import {
  getPermissionsByIdUser,
  updatePermissionByUser,
} from "../../../servicesHTTP";
import { messageSweetalert } from "../../../utilities";

export default function EditPermissions({
  open,
  data,
  handleClose,
  options,
}: any) {
  const [permission, setPermission] = useState([]);
  const [errors, setErrors] = useState<any>({});
  const { setShowLoading } = severalStore();

  const getPermissionsByIdUserLocal = useCallback(
    async (idUser: any) => {
      setShowLoading(true);
      const data = await getPermissionsByIdUser(idUser);
      if (data.status === "ok") {
        setPermission(data.data);
      }
      setShowLoading(false);
    },
    [setShowLoading]
  );

  useEffect(() => {
    getPermissionsByIdUserLocal(data.id);
  }, [getPermissionsByIdUserLocal, data.id]);

  const handleClickSave = () => {
    send(permission);
  };

  const send = async (permissionData: any) => {
    const sendData = permissionData.map((x: any) => ({
      id: x.id,
      fa: x.fa,
      eco: x.eco,
      fede: x.fede,
    }));

    const result = await updatePermissionByUser(sendData);
    if (result.status === "ok") {
      messageSweetalert("Terminado", <p>Usuario actualizado</p>);
      await getPermissionsByIdUserLocal(data.id);
    } else if (result.status === "no_found")
      messageSweetalert("Datos no actualizados", "info");
    else if (result.status !== "error")
      messageSweetalert(result.message, <p>Solicitud no enviada.</p>, "error");
    setShowLoading(false);
    handleClose(false);
  };

  const handleChange = (e: any, idRecord: any) => {
    const { name, value } = e.target;

    setPermission((x: any) => {
      return x.map((y: any) => {
        if (String(y.id) === String(idRecord)) {
          return { ...y, [name]: value };
        }
        return { ...y };
      });
    });
    setErrors({});
  };

  return (
    <Modal
      title={"Editar permisos"}
      centered
      open={open}
      onOk={handleClickSave}
      okText={"Modificar"}
      onCancel={() => handleClose(false)}
      width={"1000px"}
      cancelText="Cerrar"
    >
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col xs={24} md={2} xl={2}>
            Id
          </Col>
          <Col xs={24} md={8} xl={8}>
            Módulo
          </Col>
          <Col xs={24} md={4} xl={4}>
            Forma Asociativa
          </Col>
          <Col xs={24} md={4} xl={4}>
            Ecomun
          </Col>
          <Col xs={24} md={4} xl={4}>
            Federación
          </Col>
        </Row>
        {permission.map((x: any) => (
          <Row key={x.id} className="row-form">
            <Col xs={24} md={2} xl={2}>
              <Form.Item>
                <Input placeholder="Id" name="id" disabled value={x.id} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={8}>
              <Form.Item>
                <Input
                  placeholder="module"
                  name="module"
                  disabled
                  value={x.application_modules.name}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={4} xl={4}>
              <Form.Item>
                <Select
                  name="fa"
                  style={{ width: "100%" }}
                  onChange={(e: any) => handleChange(e, x.id)}
                  value={x.fa}
                  placeholder="Please select"
                  option0={false}
                  options={options.map((x: any) => ({
                    value: x.id,
                    label: x.description,
                  }))}
                />
              </Form.Item>
              {errors?.active && (
                <Alert
                  style={{ marginTop: "-20px" }}
                  message={errors.fa}
                  type="error"
                  showIcon
                />
              )}
            </Col>
            <Col xs={24} md={4} xl={4}>
              <Form.Item>
                <Select
                  name="eco"
                  style={{ width: "100%" }}
                  onChange={(e: any) => handleChange(e, x.id)}
                  value={x.eco}
                  placeholder="Please select"
                  option0={false}
                  options={options.map((x: any) => ({
                    value: x.id,
                    label: x.description,
                  }))}
                />
              </Form.Item>
              {errors?.eco && (
                <Alert
                  style={{ marginTop: "-20px" }}
                  message={errors.eco}
                  type="error"
                  showIcon
                />
              )}
            </Col>
            <Col xs={24} md={4} xl={4}>
              <Form.Item>
                <Select
                  name="fede"
                  style={{ width: "100%" }}
                  onChange={(e: any) => handleChange(e, x.id)}
                  value={x.fede}
                  placeholder="Please select"
                  option0={false}
                  options={options.map((x: any) => ({
                    value: x.id,
                    label: x.description,
                  }))}
                />
              </Form.Item>
              {errors?.fede && (
                <Alert
                  style={{ marginTop: "-20px" }}
                  message={errors.fede}
                  type="error"
                  showIcon
                />
              )}
            </Col>
          </Row>
        ))}
      </Form>
    </Modal>
  );
}
