import React, { useCallback, useEffect, useState } from "react";
import { Loading, Table } from "../../../components";
import { Button, Col, Flex, Row } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { getUsers } from "../../../servicesHTTP";
import { severalStore } from "../../../stores";
import EditUser from "./EditUser";
import EditPermissions from "./EditPermissions";
import { messageSweetalert } from "../../../utilities";

/* const initialData = {
  id: 0,
  active: false,
  cellphone_number: "",
  email: "",
  name: "",
  status: "",
  user_type_id: "",
  associative_form_id: "",
  email_validation: "",
}; */

const userColumns = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
    width: 50,
  },
  {
    title: "Activo",
    dataIndex: "active",
    key: "active",
    width: 50,
    render: (text: any, record: any, index: any) => {
      return `${record.active ? "Si" : "No"}`;
    },
  },
  {
    title: "Correo",
    dataIndex: "email",
    key: "email",
    width: 200,
  },
  {
    title: "Nombres y Apellidos",
    dataIndex: "name",
    key: "name",
    width: 250,
  },
  {
    title: "Estado",
    dataIndex: "user_status",
    key: "user_status",
    width: 100,
    render: (text: any, record: any, index: any) => {
      return `${record.user_status ? record.user_status.description : ""}`;
    },
  },
  {
    title: "Tipo de usuario",
    dataIndex: "user_type",
    key: "user_type",
    width: 150,
    render: (text: any, record: any, index: any) => {
      return `${record.user_type ? record.user_type.description : ""}`;
    },
  },
  {
    title: "Pertenezco a",
    dataIndex: "associative_form",
    key: "associative_form",
    width: 250,
    render: (text: any, record: any, index: any) => {
      return `${record.associative_form ? record.associative_form.name : ""}`;
    },
  },
  {
    title: "Validación de correo",
    dataIndex: "email_validation",
    key: "email_validation",
    width: 50,
    render: (text: any, record: any, index: any) => {
      return `${record.email_validation ? "Si" : "No"}`;
    },
  },
];

export default function Users() {
  const { setShowLoading }: any = severalStore();
  const [users, setUsers] = useState<any[]>([]);
  const [options, setOptions] = useState<any>({
    user_type: [],
    status: [],
    fa: [],
    optionsPermissions: [],
  });

  const [modalEditUSerOpen, setModalEditUSerOpen] = useState<boolean>(false);
  const [modalEditPermissionsOpen, setModalEditPermissionsOpen] =
    useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);

  useEffect(() => {
    if (selected.length > 0) {
      const pre: any = users.filter((x: any) => x.id === selected[0].id);
      if (pre) {
        setSelected(pre);
      }
    }
    // eslint-disable-next-line
  }, [users]);

  const getUsersLocal = useCallback(async () => {
    setShowLoading(true);

    const data = await getUsers();
    if (data.status === "ok") {
      setUsers(data.data.users);
      setOptions({
        user_type: data.data.userTypes,
        status: data.data.userStatus,
        fa: data.data.AssociativeForms,
        optionsPermissions: data.data.typeAccess,
      });
    }
    setShowLoading(false);
  }, [setShowLoading]);

  useEffect(() => {
    (async () => await getUsersLocal())();
  }, [getUsersLocal]);

  const onChangeSelect = (newSelectedRowKeys: any, newSelectedRows: any) => {
    setSelected(newSelectedRows);
  };

  const openEditUser = (selected: any[]) => {
    isSelected(selected);
    setModalEditUSerOpen(true);
  };

  const isSelected = (selected: any[]) => {
    if (selected.length > 1) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar mas de 1 registro</p>,
        "error"
      );
      return;
    }

    if (selected.length <= 0) {
      messageSweetalert(
        "Terminado",
        <p>Debe seleccionar 1 registro</p>,
        "error"
      );
      return;
    }
  }

  const openEditPermissions = (selected: any) => {
    isSelected(selected);
    setModalEditPermissionsOpen(true);
  };

  return (
    <>
      <Row
        style={{
          marginTop: 20,
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingBottom: 10,
        }}
      >
        <Col span={24}>
          <div className="mt-4">
            <h2 className="associative-forms-list-title">Lista de usuarios</h2>
          </div>
        </Col>
      </Row>

      <Row style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <Col span={24}>
          <Flex style={{ marginBottom: 10 }}>
            <Button
              type="primary"
              size="large"
              icon={<EditOutlined />}
              style={{ marginRight: 10 }}
              disabled={selected.length <= 0}
              onClick={() => openEditUser(selected)}
            >
              Editar usuario
            </Button>
            <Button
              type="primary"
              size="large"
              icon={<EditOutlined />}
              disabled={selected.length <= 0}
              onClick={() => openEditPermissions(selected)}
            >
              Editar permisos
            </Button>
          </Flex>
          <Table
            dataSource={users}
            columns={userColumns}
            bordered
            onChangeSelect={onChangeSelect}
          />
        </Col>
        {modalEditUSerOpen && (
          <EditUser
            open={modalEditUSerOpen}
            handleClose={setModalEditUSerOpen}
            getUsersLocal={getUsersLocal}
            data={selected[0]}
            options={options}
          />
        )}
        {modalEditPermissionsOpen && (
          <EditPermissions
            open={modalEditPermissionsOpen}
            handleClose={setModalEditPermissionsOpen}
            data={selected[0]}
            options={options.optionsPermissions}
          />
        )}
      </Row>
      <Loading />
    </>
  );
}
