import { Button, Col, Flex, Row } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { enableButtonSave, messageSweetalert } from "../../../../../utilities";
import { applicationModules } from "../../../../../constants";
import { userStore } from "../../../../../stores";
import { Table } from "../../../../../components";
import CreateEdit from "./CreateEdit";

const columns = [
  {
    title: "Descripción",
    dataIndex: "description",
  },
];

const initialData = {
  id: 0,
  process_id: 0,
  description: "",
};

function Home({ handleChange, record }: any) {
  const { permissions } = userStore();
  const [types, setTypes] = useState<any>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setTypes([
      ...record.processes_types.map((x: any) => ({ ...x, key: x.id })),
    ]);
  }, [record.processes_types]);

  const openCreate = (selected: any[]) => {
    if (selected.length > 0) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar ningún registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const openEdit = (selected: any[]) => {
    if (selected.length > 1) {
      messageSweetalert(
        "Terminado",
        <p>No debe seleccionar mas de 1 registro</p>,
        "error"
      );
      return;
    }

    if (selected.length <= 0) {
      messageSweetalert(
        "Terminado",
        <p>Debe seleccionar 1 registro</p>,
        "error"
      );
      return;
    }
    setModalOpen(true);
  };

  const onChangeSelect = (newSelectedRowKeys: any, newSelectedRows: any) => {
    setSelected(newSelectedRows);
  };

  const create = (data: any) => {
    const a = [...types, { ...data, new: false }];
    handleChange({ target: { name: "processes_types", value: a } });
    setModalOpen(false);
  };

  const edit = (data: any) => {
    const a = types.map((x: any) => {
      if (String(x.id) !== String(data.id)) return { ...x };
      return { ...data, new: false };
    });
    handleChange({ target: { name: "processes_types", value: a } });
    setSelected([data]);
    setModalOpen(false);
  };

  return (
    <>
      <Row>
        <Col>
          <p className="section-form-title">
            ¿Qué tipo de procesos implementa o desarrolla en sus actividades
            económicas?
          </p>
        </Col>
      </Row>
      <Row className="row-form">
        <Col span={23}>
          <Flex style={{ marginBottom: 10 }}>
            {enableButtonSave(
              applicationModules,
              permissions,
              "economicActivity"
            ) && (
              <>
                <Button
                  type="primary"
                  size="large"
                  icon={<PlusOutlined />}
                  style={{ marginRight: 10 }}
                  disabled={selected.length > 0}
                  onClick={() => openCreate(selected)}
                >
                  Agregar
                </Button>
                <Button
                  type="primary"
                  size="large"
                  icon={<EditOutlined />}
                  disabled={selected.length <= 0}
                  onClick={() => openEdit(selected)}
                >
                  Editar
                </Button>
              </>
            )}
          </Flex>
          <Table
            dataSource={types || []}
            columns={columns}
            bordered
            scroll={{ x: "1000" }}
            onChangeSelect={onChangeSelect}
          />
        </Col>
        {modalOpen && (
          <CreateEdit
            open={modalOpen}
            handleClose={setModalOpen}
            data={
              selected.length > 0
                ? selected[0]
                : {
                    ...initialData,
                    new: true,
                  }
            }
            create={create}
            edit={edit}
          />
        )}
      </Row>
    </>
  );
}

export default Home;
