import { useState, useEffect, useRef, useCallback } from "react";
import Header from "../header/Header";
import { Map } from "../../../components";
// @ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { useNavigate } from "react-router-dom";
// @ts-ignore
import FilterMap from "./filterMap/Index";
import FootMap from "./footerMap/Home";
import { paxios } from "../../../http";
import { URL_SERVER } from "../../../config";
import { severalStore } from "../../../stores";

function HomePublic({ urlReload }: any) {
  const navigate = useNavigate();
  const { setFaSelectdMap, setIpSelectedMap } = severalStore();
  const mapRef = useRef<any>(null);
  const [mapInfoFa, setMapInfoFa] = useState<any>(null);
  const [mapInfoFaVisible, setMapInfoFaVisible] = useState<any>(null);
  const [mapInfoIp, setMapInfoIp] = useState<any>(null);
  const [mapInfoIpVisible, setMapInfoIpVisible] = useState<any>(null);
  const [mapInfoPp, setMapInfoPp] = useState<any>(null);
  const [mapInfoPpVisible, setMapInfoPpVisible] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState(0);

  const getAssociativeFormsMap = async () => {
    try {
      const u = await paxios.get(
        `${URL_SERVER}/home_public/associative_forms_map`
      );
      setMapInfoFa(u.data);
      setMapInfoFaVisible(u.data);
    } catch (error) {}
  };

  const getProjectMap = async () => {
    try {
      const u = await paxios.get(`${URL_SERVER}/home_public/projects_map`);
      const pi = {
        crs: u.data.crs,
        type: u.data.type,
        features: u.data.features.filter(
          (x: any) => x.properties.type_project === "I"
        ),
      };
      const pp = {
        crs: u.data.crs,
        type: u.data.type,
        features: u.data.features.filter(
          (x: any) => x.properties.type_project === "P"
        ),
      };
      setMapInfoIp(pi);
      setMapInfoIpVisible(pi);

      setMapInfoPp(pp);
      setMapInfoPpVisible(pp);
    } catch (error) {}
  };

  useEffect(() => {
    getAssociativeFormsMap();
  }, []);

  const clearMap = () => {
    if (mapRef.current.getMap().getLayer(`cluster-map-info-fa`)) {
      mapRef.current.getMap().removeLayer(`cluster-map-info-fa`);
    }
    if (mapRef.current.getMap().getLayer(`unclustered-map-info-fa`)) {
      mapRef.current.getMap().removeLayer(`unclustered-map-info-fa`);
    }
    if (mapRef.current.getMap().getSource(`source-map-info-fa`)) {
      mapRef.current.getMap().removeSource(`source-map-info-fa`);
    }

    if (mapRef.current.getMap().getLayer(`cluster-map-info-ip`)) {
      mapRef.current.getMap().removeLayer(`cluster-map-info-ip`);
    }
    if (mapRef.current.getMap().getLayer(`unclustered-map-info-ip`)) {
      mapRef.current.getMap().removeLayer(`unclustered-map-info-ip`);
    }
    if (mapRef.current.getMap().getSource(`source-map-info-ip`)) {
      mapRef.current.getMap().removeSource(`source-map-info-ip`);
    }

    if (mapRef.current.getMap().getLayer(`cluster-map-info-pp`)) {
      mapRef.current.getMap().removeLayer(`cluster-map-info-pp`);
    }
    if (mapRef.current.getMap().getLayer(`unclustered-map-info-pp`)) {
      mapRef.current.getMap().removeLayer(`unclustered-map-info-pp`);
    }
    if (mapRef.current.getMap().getSource(`source-map-info-pp`)) {
      mapRef.current.getMap().removeSource(`source-map-info-pp`);
    }
  };

  const loadMapInfoFa = useCallback(
    (mapInfoFa: any) => {
      if (mapInfoFa && mapRef) {
        clearMap();
        mapRef.current.getMap().addSource("source-map-info-fa", {
          type: "geojson",
          data: mapInfoFa,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        });

        mapRef.current.getMap().addLayer({
          id: `cluster-map-info-fa`,
          type: "symbol",
          source: `source-map-info-fa`,
          filter: ["has", "point_count"],
          layout: {
            "icon-image": "custom-marker",
            // get the title name from the source's "title" property
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 9,
            "text-offset": [0, -0.4],
            "text-allow-overlap": true,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
          },
          paint: {
            "text-color": "#000",
          },
        });

        mapRef.current.getMap().addLayer({
          id: `unclustered-map-info-fa`,
          type: "symbol",
          source: `source-map-info-fa`,
          filter: ["!", ["has", "point_count"]],
          layout: {
            "text-field": "1",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 9,
            "text-offset": [0, -0.4],
            "icon-image": "custom-marker",
            "text-allow-overlap": true,
            "icon-ignore-placement": true,
            "icon-allow-overlap": true,
          },
        });

        const popup = new mapboxgl.Popup({
          closeButton: true,
          closeOnClick: false,
          offset: 5,
        });

        mapRef.current
          .getMap()
          .on("click", `unclustered-map-info-fa`, (e: any) => {
            // Copy coordinates array.
            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.name;
            const form = e.features[0].properties;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            const atag = document.createElement("div");
            atag.innerHTML = `<a class="watch-list">${description}</a>`;
            atag.addEventListener("click", (e) => {
              setFaSelectdMap(form);
              navigate("/associative-form-view");
            });
            popup.setDOMContent(atag);

            popup.setLngLat(coordinates).addTo(mapRef.current.getMap());
          });
      }
    },
    [navigate, setFaSelectdMap]
  );

  useEffect(() => {
    loadMapInfoFa(mapInfoFaVisible);
  }, [mapInfoFaVisible, loadMapInfoFa]);

  const loadMapInfoIp = useCallback(
    (mapInfoIp: any) => {
      if (mapInfoIp && mapRef) {
        clearMap();
        mapRef.current.getMap().addSource("source-map-info-ip", {
          type: "geojson",
          data: mapInfoIp,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        });

        mapRef.current.getMap().addLayer({
          id: `cluster-map-info-ip`,
          type: "symbol",
          source: `source-map-info-ip`,
          filter: ["has", "point_count"],
          layout: {
            "icon-image": "custom-marker-ip",
            // get the title name from the source's "title" property
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 9,
            "text-offset": [0, -0.4],
            "text-allow-overlap": true,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
          },
          paint: {
            "text-color": "#000",
          },
        });

        mapRef.current.getMap().addLayer({
          id: `unclustered-map-info-ip`,
          type: "symbol",
          source: `source-map-info-ip`,
          filter: ["!", ["has", "point_count"]],
          layout: {
            "text-field": "1",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 9,
            "text-offset": [0, -0.4],
            "icon-image": "custom-marker-ip",
            "text-allow-overlap": true,
            "icon-ignore-placement": true,
            "icon-allow-overlap": true,
          },
        });

        const popup = new mapboxgl.Popup({
          closeButton: true,
          closeOnClick: false,
          offset: 5,
        });

        mapRef.current
          .getMap()
          .on("click", `unclustered-map-info-ip`, (e: any) => {
            // Copy coordinates array.
            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.name;
            const form = e.features[0].properties;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            const atag = document.createElement("div");
            atag.innerHTML = `<a class="watch-list">${description}</a>`;
            atag.addEventListener("click", (e) => {
              setIpSelectedMap(form);
              navigate("/project-iniciative-view");
            });
            popup.setDOMContent(atag);

            popup.setLngLat(coordinates).addTo(mapRef.current.getMap());
          });
      }
    },
    [navigate, setIpSelectedMap]
  );

  const loadMapInfoPp = useCallback(
    (mapInfoPp: any) => {
      if (mapInfoPp && mapRef) {
        //clearMap();
        mapRef.current.getMap().addSource("source-map-info-pp", {
          type: "geojson",
          data: mapInfoPp,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        });

        mapRef.current.getMap().addLayer({
          id: `cluster-map-info-pp`,
          type: "symbol",
          source: `source-map-info-pp`,
          filter: ["has", "point_count"],
          layout: {
            "icon-image": "custom-marker-pp",
            // get the title name from the source's "title" property
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 9,
            "text-offset": [0, -0.4],
            "text-allow-overlap": true,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
          },
          paint: {
            "text-color": "#000",
          },
        });

        mapRef.current.getMap().addLayer({
          id: `unclustered-map-info-pp`,
          type: "symbol",
          source: `source-map-info-pp`,
          filter: ["!", ["has", "point_count"]],
          layout: {
            "text-field": "1",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 9,
            "text-offset": [0, -0.4],
            "icon-image": "custom-marker-pp",
            "text-allow-overlap": true,
            "icon-ignore-placement": true,
            "icon-allow-overlap": true,
          },
        });

        const popup = new mapboxgl.Popup({
          closeButton: true,
          closeOnClick: false,
          offset: 5,
        });

        mapRef.current
          .getMap()
          .on("click", `unclustered-map-info-pp`, (e: any) => {
            // Copy coordinates array.
            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.name;
            const form = e.features[0].properties;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            const atag = document.createElement("div");
            atag.innerHTML = `<a class="watch-list">${description}</a>`;
            atag.addEventListener("click", (e) => {
              setIpSelectedMap(form);
              navigate("/project-iniciative-view");
            });
            popup.setDOMContent(atag);

            popup.setLngLat(coordinates).addTo(mapRef.current.getMap());
          });
      }
    },
    [navigate, setIpSelectedMap]
  );

  const loadIpPp = useCallback(
    (mapInfoIp: any, mapInfoPp: any) => {
      loadMapInfoIp(mapInfoIp);
      loadMapInfoPp(mapInfoPp);
    },
    [loadMapInfoIp, loadMapInfoPp]
  );

  useEffect(() => {
    loadIpPp(mapInfoIpVisible, mapInfoPpVisible);
  }, [loadIpPp, mapInfoPpVisible, mapInfoIpVisible]);

  const filterMapInfoDepartment = (info: any, departmentId: any) => {
    if (String(departmentId) === "0") {
      setMapInfoFaVisible(info);
      return;
    }
    const newInfo = {
      crs: info.crs,
      type: info.type,
      features: info.features.filter(
        (x: any) => String(x.properties.department_id) === String(departmentId)
      ),
    };
    setMapInfoFaVisible(newInfo);
  };

  const filterMapInfoMunicipality = (info: any, municipalityId: any) => {
    if (String(municipalityId) === "0") {
      setMapInfoFaVisible(info);
      return;
    }
    const newInfo = {
      crs: info.crs,
      type: info.type,
      features: info.features.filter(
        (x: any) =>
          String(x.properties.municipality_id) === String(municipalityId)
      ),
    };
    setMapInfoFaVisible(newInfo);
  };

  const filterMapInfoIpPpDepartment = (
    infoIp: any,
    infoPp: any,
    departmentId: any
  ) => {
    if (String(departmentId) === "0") {
      setMapInfoIpVisible(infoIp);
      setMapInfoPpVisible(infoPp);
      return;
    }
    const newInfoIp = {
      crs: infoIp.crs,
      type: infoIp.type,
      features: infoIp.features.filter(
        (x: any) =>
          String(x.properties.department_code) === String(departmentId)
      ),
    };

    const newInfoPp = {
      crs: infoPp.crs,
      type: infoPp.type,
      features: infoPp.features.filter(
        (x: any) =>
          String(x.properties.department_code) === String(departmentId)
      ),
    };
    setMapInfoIpVisible(newInfoIp);
    setMapInfoPpVisible(newInfoPp);
  };

  const filterMapInfoIpPpMunicipality = (
    infoIp: any,
    infoPp: any,
    municipalityId: any
  ) => {
    if (String(municipalityId) === "0") {
      setMapInfoIpVisible(infoIp);
      setMapInfoPpVisible(infoPp);
      return;
    }
    const newInfoIp = {
      crs: infoIp.crs,
      type: infoIp.type,
      features: infoIp.features.filter(
        (x: any) =>
          String(x.properties.municipality_code) === String(municipalityId)
      ),
    };

    const newInfoPp = {
      crs: infoPp.crs,
      type: infoPp.type,
      features: infoPp.features.filter(
        (x: any) =>
          String(x.properties.municipality_code) === String(municipalityId)
      ),
    };
    setMapInfoIpVisible(newInfoIp);
    setMapInfoPpVisible(newInfoPp);
  };

  const onChangeDepartment = (department: string) => {
    if (currentTab === 0) {
      filterMapInfoDepartment(mapInfoFa, department);
    }

    if (currentTab === 1) {
      filterMapInfoIpPpDepartment(mapInfoIp, mapInfoPp, department);
    }
  };

  const onChangeMunicipality = (municipality: string) => {
    if (currentTab === 0) {
      filterMapInfoMunicipality(mapInfoFaVisible, municipality);
    }

    if (currentTab === 1) {
      filterMapInfoIpPpMunicipality(
        mapInfoIpVisible,
        mapInfoPpVisible,
        municipality
      );
    }
  };

  const changeTab = (tab: number) => {
    clearMap();
    setCurrentTab(tab);
    if (tab === 0) {
      getAssociativeFormsMap();
      return;
    }

    if (tab === 1) {
      getProjectMap();
      return;
    }
  };

  const onChangeTab = (tab: number) => {
    changeTab(tab);
  };

  /* if (urlReload !== "/" && urlReload !== "/login") {
    return <Navigate to={"/login"} />;
  } */

  return (
    <>
      <Header />
      <Map
        ref={mapRef}
        floatingMenu={
          <FilterMap
            onChangeTab={onChangeTab}
            onChangeDepartment={onChangeDepartment}
            onChangeMunicipality={onChangeMunicipality}
          />
        }
        floatingFooter={<FootMap />}
      ></Map>
    </>
  );
}

export default HomePublic;
