import { Form, Modal } from "antd";
import { useEffect, useState } from "react";
import { enableButtonSave, messageSweetalert } from "../../../../utilities";
import { CircularProgressWithLabel, Accordion } from "../../../../components";
import GeneralInformation from "./GeneralInformation";
import AdditionalInformation from "./AdditionalInformation";
import { applicationModules } from "../../../../constants";
import { userStore } from "../../../../stores";

const validateDataGeneral = (data: any) => {
  const errors: any = {
    first_name: false,
    last_name: false,
    document_number: false,
    birth_date: false,
  };
  if (data.first_name === "") errors.first_name = true;
  if (data.last_name === "") errors.last_name = true;
  if (data.document_number === "") errors.document_number = true;
  if (data.birth_date === "") errors.birth_date = true;

  return errors;
};

const validateDataAditional = (data: any) => {
  const errors: any = {
    admission_date: false,
    vinculation_type: false,
    work_position_id: false,
    associate_role_id: false,
    associate_role_committee_id: false,
    population_group: false,
    schooling_level: false,
    marital_status: false,
    associative_situation_id: false,
    cellphone_number: false,
    email: false,
  };

  if (data.admission_date === "") errors.admission_date = true;
  if (String(data.vinculation_type) === "0" || data.vinculation_type === null)
    errors.vinculation_type = true;
  //if (String(data.work_position_id) === "0") errors.work_position_id = true;
  //if (String(data.associate_role_id) === "0") errors.associate_role_id = true;
  if (
    String(data.associate_role_committee_id) === "0" ||
    data.associate_role_committee_id === null
  )
    errors.associate_role_committee_id = true;
  //if (String(data.population_group) === "0") errors.population_group = true;
  if (String(data.schooling_level) === "0" || data.schooling_level === null)
    errors.schooling_level = true;
  if (String(data.marital_status) === "0" || data.marital_status === null)
    errors.marital_status = true;
  if (
    String(data.associative_situation_id) === "0" ||
    data.associative_situation_id === null
  )
    errors.associative_situation_id = true;
  if (data.cellphone_number === "" || data.cellphone_number === null)
    errors.cellphone_number = true;
  //if (data.email === "" || data.email === null) errors.email = true;

  return errors;
};

function CreateEdit({ open, handleClose, data, saveRecord, setData }: any) {
  const { permissions } = userStore();
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...data, ...values });
  }, [form, data]);

  const validateExistError = (errors: any) => {
    let result = false;
    const keys = Object.keys(errors);
    for (let i = 0; i < keys.length; i++) {
      if (errors[keys[i]]) {
        result = true;
      }
    }
    return result;
  };

  const save = (data: any) => {
    saveRecord(data);
  };

  const messageErrorData = () => {
    messageSweetalert("Revisar", <p>Revisar campos.</p>, "error");
  };

  const handleClickSaveGeneral = () => {
    const values = form.getFieldsValue(); // getValues();
    const a = { ...data, ...values };
    const errors = validateDataGeneral(a);
    setErrors(errors);
    validateExistError(errors);
    if (!validateExistError(errors)) {
      save(a);
    } else {
      messageErrorData();
    }
  };

  const handleClickSaveAditional = () => {
    const values = form.getFieldsValue(); ///getValues();
    const a = { ...data, ...values };
    const errors = validateDataAditional(a);
    setErrors(errors);
    if (!validateExistError(errors)) {
      save(a);
    } else {
      messageErrorData();
    }
  };

  const handleClickSave = () => {
    const values = form.getFieldsValue(); //getValues();
    const a = { ...data, ...values };
    const errors = { ...validateDataGeneral(a), ...validateDataAditional(a) };
    setErrors(errors);
    if (!validateExistError(errors)) {
      save(a);
    } else {
      messageErrorData();
    }
  };

  const setValues = (name: string, value: any) => {
    setData((prev: any) => ({ ...prev, [name]: value }));
  };

  return (
    <Modal
      title={`${data.new ? "Crear" : "Editar"}`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={"Guardar"}
      onCancel={() => handleClose(false)}
      width={"1000px"}
      cancelText="Cerrar"
      okButtonProps={{
        style: {
          display: enableButtonSave(applicationModules, permissions)
            ? ""
            : "none",
        },
      }}
    >
      <Form
        form={form}
        name="normal_login"
        initialValues={data}
        //onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
        autoComplete="off"
        size="large"
      >
        <Accordion
          size={"large"}
          items={[
            {
              key: "1",
              label: "Información general",
              children: (
                <GeneralInformation
                  handleClickSave={handleClickSaveGeneral}
                  errors={errors}
                  data={data}
                  setValues={setValues}
                />
              ),
              extra: (
                <CircularProgressWithLabel
                  size={35}
                  value={data.percentage_progress_information.percentageGeneral}
                />
              ),
            },
            {
              key: "2",
              label: "Información adicional",
              children: (
                <AdditionalInformation
                  handleClickSave={handleClickSaveAditional}
                  errors={errors}
                  associate={data}
                  setValues={setValues}
                  formAnt={form}
                />
              ),
              extra: (
                <CircularProgressWithLabel
                  size={35}
                  value={
                    data.percentage_progress_information.percentageAdditional
                  }
                />
              ),
            },
          ]}
        />
      </Form>
    </Modal>
  );
}

export default CreateEdit;
