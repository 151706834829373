import { Alert, Col, Form, Input, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import uuid from "react-uuid";

const validateData = (data: any) => {
  const errors: any = {};
  if (data.description === "") errors.description = true;

  return errors;
};

function CreateEdit({ open, handleClose, data, create, edit }: any) {
  const [type, setType] = useState({ ...data });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setType({ ...data });
  }, [data]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setType((x: any) => ({ ...x, [name]: value }));
    setErrors({});
  };

  const handleClickSave = () => {
    const errors = validateData(type);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (type.new) {
      const uid = uuid();
      const newBranche = {
        ...type,
      };
      create({ ...newBranche, id: uid, key: uid });
      return;
    }

    edit({ ...type, new: false });
    setErrors({});
  };

  return (
    <Modal
      title={`${data.new ? "Agregar" : "Editar"}`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={`${data.new ? "Agregar" : "Modificar"}`}
      onCancel={() => handleClose(false)}
      width={"1000px"}
      cancelText="Cerrar"
    >
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Descripción">
              <Input
                placeholder="Descripción"
                name="description"
                onChange={handleChange}
                value={type.description}
              />
            </Form.Item>
            {errors?.department_id && (
              <Alert message={errors.department_id} type="error" showIcon />
            )}
          </Col>
          <Col xs={24} md={11} xl={11}></Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateEdit;
