import { Alert, Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Select } from "../../../components";
import { updateUser } from "../../../servicesHTTP";
import { Loading } from "../../../components";
import { messageSweetalert } from "../../../utilities";
import { severalStore } from "../../../stores";

export default function EditUser({
  open,
  data,
  handleClose,
  options,
  getUsersLocal
}: any) {
  const [user, setUser] = useState({ ...data });
  const [errors, setErrors] = useState<any>({});
  const { setShowLoading } = severalStore();

  useEffect(() => {
    setUser({ ...data });
  }, [data]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUser((x: any) => ({ ...x, [name]: value }));
    setErrors({});
  };

  const validateData = (data: any) => {
    const errors: any = {};
    if (data.id === "") errors.id = true;
    if (data.active === "") errors.active = true;
    if (data.email === "") errors.email = true;
    if (data.name === "") errors.name = true;
    if (String(data.status) === "0") errors.status = true;
    if (String(data.user_type_id) === "0") errors.user_type_id = true;
    if (String(data.email_validation) === "0") errors.email_validation = true;
    if (data.cellphone_number === "") errors.cellphone_number = true;
    return errors;
  };

  const handleClickSave = () => {
    const errors = validateData(user);
    if (Object.keys(errors).length <= 0) {
      handleSend({ ...user });
      return;
    }
    setErrors(errors);
  };

  const handleSend = async (data: any) => {
    const formData = new FormData();
    const keysData = Object.keys(data);
    keysData.forEach((key) => {
      formData.append(key, data[key]);
    });

    setShowLoading(true);

    const result = await updateUser(data);
    if (result.status === "ok") {
      messageSweetalert("Terminado", <p>Usuario actualizado</p>);
      await getUsersLocal()
    } else if (result.status === "no_found")
      messageSweetalert("Datos no actualizados", "info");
    else if (result.status !== "error")
      messageSweetalert(result.message, <p>Solicitud no enviada.</p>, "error");
    setShowLoading(false);
    handleClose(false);
  };

  return (
    <Modal
      title={"Editar usuario"}
      centered
      open={open}
      onOk={handleClickSave}
      okText={"Modificar"}
      onCancel={() => handleClose(false)}
      width={"1000px"}
      cancelText="Cerrar"
    >
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Id">
              <Input
                placeholder="Id"
                name="id"
                disabled
                onChange={handleChange}
                value={user.id}
              />
            </Form.Item>
            {errors?.id && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.id}
                type="error"
                showIcon
              />
            )}
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Activo">
              <Select
                name="active"
                style={{ width: "100%" }}
                onChange={handleChange}
                value={user.active}
                placeholder="Please select"
                option0={false}
                options={[
                  { value: true, label: "Activo" },
                  { value: false, label: "Inactivo" },
                ]}
              />
            </Form.Item>
            {errors?.active && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.active}
                type="error"
                showIcon
              />
            )}
          </Col>
        </Row>
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Correo electrónico">
              <Input
                name="email"
                placeholder="Correo electrónico"
                onChange={handleChange}
                value={user.email}
              />
            </Form.Item>
            {errors?.email && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.email}
                type="error"
                showIcon
              />
            )}
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Nombres y Apellidos">
              <Input
                name="name"
                placeholder="Nombres y Apellidos"
                onChange={handleChange}
                value={user.name}
              />
            </Form.Item>
            {errors?.name && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.name}
                type="error"
                showIcon
              />
            )}
          </Col>
        </Row>
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Estado">
              <Select
                name={"status"}
                value={user.status}
                style={{ width: "100%" }}
                placeholder="Please select"
                options={options.status.map((x: any) => ({
                  value: x.id,
                  label: x.description,
                }))}
                onChange={handleChange}
              />
              {errors?.status && (
                <Alert message={errors.status} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Tipo de usuario">
              <Select
                name={"user_type_id"}
                value={user.user_type_id}
                style={{ width: "100%" }}
                placeholder="Please select"
                options={options.user_type.map((x: any) => ({
                  value: x.id,
                  label: x.description,
                }))}
                onChange={handleChange}
              />
              {errors?.user_type && (
                <Alert message={errors.user_type_id} type="error" showIcon />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Pertenezco a">
              <Select
                name={"associative_form_id"}
                value={user.associative_form_id}
                style={{ width: "100%" }}
                placeholder="Please select"
                options={options.fa.map((x: any) => ({
                  value: x.id,
                  label: `${x.id} - ${x.name}`,
                }))}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Validación de correo">
              <Select
                name="email_validation"
                style={{ width: "100%" }}
                onChange={handleChange}
                value={user.email_validation}
                placeholder="Please select"
                option0={false}
                options={[
                  { value: true, label: "Si" },
                  { value: false, label: "No" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="row-form">
          <Col xs={24} md={11} xl={11}>
            <Form.Item label="Celular">
              <Input
                type="number"
                name="cellphone_number"
                placeholder="Celular"
                onChange={handleChange}
                value={user.cellphone_number}
              />
            </Form.Item>
            {errors?.cellphone_number && (
              <Alert
                style={{ marginTop: "-20px" }}
                message={errors.cellphone_number}
                type="error"
                showIcon
              />
            )}
          </Col>
        </Row>
      </Form>
      <Loading />
    </Modal>
  );
}
