import { useState } from "react";
import { Col, Form, Modal, Row } from "antd";
import { TabsTitle } from "../../../../components";
import ProcessesAccordion from "./ProcessesAccordion";
import InfrastructureAccordion from "./InfrastructureAccordion";
import { messageSweetalert } from "../../../../utilities";

const validateData = (data: any) => {
  const errors: any = {};

  if (data.name === "") errors.name = "Ingrese nombre";

  return errors;
};

function CreateEdit({ open, handleClose, data, save }: any) {
  const [record, setRecord] = useState({ ...data });
  const [value, setValue] = useState<number>(1);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setRecord((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleClickSave = () => {
    const errors = validateData(record);
    if (Object.keys(errors).length <= 0) {
      save(record);
    } else {
      messageSweetalert(
        "Revisar",
        <div>
          {Object.keys(errors).map((x: any) => (
            <div key={x}>{errors[x]}</div>
          ))}
        </div>,
        "warning"
      );
    }
  };
  return (
    <Modal
      title={`${data.new ? "Agregar" : "Editar"}`}
      centered
      open={open}
      onOk={handleClickSave}
      okText={"Guardar"}
      onCancel={() => handleClose(false)}
      width={"1000px"}
      cancelText="Cerrar"
    >
      <Form layout="vertical" autoComplete="off">
        <Row className="row-form">
          <Col span={24}>
            <TabsTitle
              options={[
                {
                  value: 1,
                  label: (
                    <div
                      className="ant-segmented-item-label"
                      title={"Procesos"}
                    >
                      {"Procesos"}
                    </div>
                  ),
                },
                {
                  value: 2,
                  label: (
                    <div
                      className="ant-segmented-item-label"
                      title={"Infraestructura"}
                    >
                      {"Infraestructura"}
                    </div>
                  ),
                },
              ]}
              value={value}
              setValue={setValue}
            />

            <div style={{ marginTop: 50 }}>
              {value === 1 && (
                <ProcessesAccordion
                  record={record}
                  handleChange={handleChange}
                />
              )}

              {value === 2 && (
                <InfrastructureAccordion
                  record={record}
                  handleChange={handleChange}
                />
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateEdit;
