import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Input,
  theme,
  Typography,
  Row,
  Col,
  Alert,
} from "antd";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Link, useParams } from "react-router-dom";

import { severalStore } from "../../../stores";
import { getDV, validateRecaptch } from "../../../servicesHTTP";
import Header from "../header/Header";
import { URL_SERVER } from "../../../config";
import { messageSweetalert } from "../../../utilities";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

const validateData = (data: any) => {
  const errors: any = {};
  if (String(data.user_type_id) === "1" || String(data.user_type_id) === "8") {
    if (data.nit === "") errors.nit = "Ingrese NIT";
    if (data.dv === "") errors.dv = "DV";
    if (data.initials === "") errors.initials = "Ingrese Sigla";
    if (data.name === "") errors.name = "Ingrese Nombre";
  }
  if (data.name_user === "") errors.name_user = "Ingrese Nombres y Apellidos";
  if (data.cellphone_number === "") errors.cellphone_number = "Ingrese Celular";
  if (data.email === "") errors.email = "Ingrese Correo Electrónico";
  if (data.password1 === "") errors.password1 = "Ingrese Contraseña";
  if (data.password2 === "") errors.password2 = "Repetir Contraseña";
  if (data.password1 !== data.password2)
    errors.password2 = "Contraseñas no coinciden";
  if (!data.recaptcha) errors.recaptcha = "Error validando reCAPTCHA";
  return errors;
};

const initialData = {
  nit: "",
  dv: "",
  initials: "",
  name: "",
  name_user: "",
  cellphone_number: "",
  email: "",
  password1: "",
  password2: "",
  recaptcha: false,
};

function Register() {
  let { id } = useParams();
  const { token: themeToken } = useToken();
  const screens = useBreakpoint();
  const { entities, getEntities } = severalStore();
  const [token, setToken] = useState("");
  const [refreshReCaptcha] = useState(false);
  const [data, setData] = useState({
    ...initialData,
    user_type_id: id,
  });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    entities.length <= 0 && getEntities();
  }, [entities.length, getEntities]);

  useEffect(() => {
    (async () => {
      if (token !== "") {
        const result = await validateRecaptch(token);
        if (result.success && result.score > 0.5) {
          setData((prev: any) => ({ ...prev, recaptcha: true }));
        }
      }
    })();
  }, [token]);

  const styles: any = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${themeToken.paddingXL}px`
        : `${themeToken.sizeXXL}px ${themeToken.padding}px`,
    },
    footer: {
      marginTop: themeToken.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    header: {
      marginBottom: themeToken.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: themeToken.colorBgContainer,
      display: "flex",
      height: "auto",
      padding: screens.md ? `${themeToken.sizeXXL}px 0px` : "0px",
      borderRadius: "5px",
      boxShadow: "1px 1px 12px 0px rgba(0, 0, 0, 0.1)",
    },
    text: {
      color: themeToken.colorTextSecondary,
    },
    title: {
      fontSize: screens.md
        ? themeToken.fontSizeHeading2
        : themeToken.fontSizeHeading3,
    },
  };

  const handleUpload = (data: any) => {
    delete data.recaptcha;
    data.password = data.password1;
    delete data.password1;
    delete data.password2;
    data.user_type_id = id;
    const formData = new FormData();
    const keysData = Object.keys(data);
    keysData.forEach((key) => {
      formData.append(key, data[key]);
    });

    fetch(URL_SERVER + "/register", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === "success") {
          messageSweetalert("Terminado", <p>Datos registrados.</p>);
          setData({ ...initialData, user_type_id: id });
        } else if (response.status === "Email has not been validated")
          messageSweetalert("Valide su correo", response.message, "info");
        else if (response.status === "Email already registered")
          messageSweetalert(
            "Correo ya registrado",
            response.message,
            "warning"
          );
        else if (response.status !== "error")
          messageSweetalert(
            response.message,
            <p>Datos registrados.</p>,
            "error"
          );
      })
      .catch(() => {
        messageSweetalert(
          "Si el error persiste contacte al administrador",
          <p>Datos no registrados.</p>,
          "error"
        );
      });
  };

  const handleVerify = async (_token: any) => {
    token === "" && setToken(_token);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prev: any) => ({ ...prev, [name]: value }));
  };

  const getDVLocal = async (nit: any) => {
    if (nit === "") return;
    const result = await getDV(nit);
    if (result.status === "ok") {
      setData((prev: any) => ({ ...prev, dv: String(result.data.dv) }));
    }
  };

  const handleBlur = (e: any) => {
    const { value } = e.target;
    getDVLocal(value);
  };

  const save = async (data: any) => {
    setData((prev: any) => ({ ...prev, dv: id }));
    setErrors({});
    const errors = validateData(data);
    if (Object.keys(errors).length <= 0) {
      handleUpload(data);
      return;
    }
    setErrors(errors);
  };

  const handleClick = () => {
    save({ ...data });
  };

  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/coffee-background.png"
          })`,
        }}
      >
        <section style={styles.section}>
          <div style={styles.container}>
            <div style={styles.header}>
              <Title style={styles.title}>Registro</Title>
            </div>
            <Form layout="vertical">
              {(String(id) === "1" || String(id) === "8") && (
                <>
                  <Text style={styles.text}>
                    Para registrarse como{" "}
                    <b>
                      {String(id) === "8" ? "Federación" : "Forma asociativa"}
                    </b>
                    , por favor diligencie la siguiente información:
                  </Text>
                  <Row className="row-form">
                    <Col xs={24} md={8} xl={8}>
                      <Form.Item
                        label="NIT"
                        tooltip={
                          <p>
                            Solo números, sin puntos, comas, guiones ni dígito
                            de verificación
                          </p>
                        }
                      >
                        <Input
                          name="nit"
                          placeholder="NIT"
                          type="number"
                          onChange={handleChange}
                          value={data.nit}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                      {errors?.nit && (
                        <Alert
                          style={{ marginTop: "-20px" }}
                          message={errors.nit}
                          type="error"
                          showIcon
                        />
                      )}
                    </Col>
                    <Col xs={24} md={3} xl={3}>
                      <Form.Item label="DV">
                        <Input
                          name="dv"
                          placeholder="DV"
                          disabled={true}
                          value={data.dv}
                        />
                      </Form.Item>
                      {errors?.dv && (
                        <Alert
                          style={{ marginTop: "-20px" }}
                          message={errors.dv}
                          type="error"
                          showIcon
                        />
                      )}
                    </Col>
                    <Col xs={24} md={8} xl={8}>
                      <Form.Item label="Sigla">
                        <Input
                          name="initials"
                          placeholder="Sigla"
                          onChange={handleChange}
                          value={data.initials}
                        />
                      </Form.Item>
                      {errors?.initials && (
                        <Alert
                          style={{ marginTop: "-20px" }}
                          message={errors.initials}
                          type="error"
                          showIcon
                        />
                      )}
                    </Col>
                  </Row>

                  <Row className="row-form">
                    <Col xs={24} md={24} xl={24}>
                      <Form.Item
                        label="Nombre"
                        tooltip={
                          <p>
                            Nombre como aparece registrado en Cámara de Comercio
                          </p>
                        }
                      >
                        <Input
                          name="name"
                          placeholder="Nombre"
                          onChange={handleChange}
                          value={data.name}
                        />
                      </Form.Item>
                      {errors?.name && (
                        <Alert
                          style={{ marginTop: "-20px" }}
                          message={errors.name}
                          type="error"
                          showIcon
                        />
                      )}
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col>
                  <p className="section-form-title">¿Quién hace el registro?</p>
                </Col>
              </Row>
              <Row className="row-form">
                <Col xs={24} md={12} xl={12}>
                  <Form.Item label="Nombres y Apellidos">
                    <Input
                      name="name_user"
                      placeholder="Nombres y Apellidos"
                      onChange={handleChange}
                      value={data.name_user}
                    />
                  </Form.Item>
                  {errors?.name_user && (
                    <Alert
                      style={{ marginTop: "-20px" }}
                      message={errors.name_user}
                      type="error"
                      showIcon
                    />
                  )}
                </Col>
                <Col xs={24} md={11} xl={11}>
                  <Form.Item label="Celular">
                    <Input
                      name="cellphone_number"
                      placeholder="Celular"
                      type="number"
                      onChange={handleChange}
                      value={data.cellphone_number}
                    />
                  </Form.Item>
                  {errors?.cellphone_number && (
                    <Alert
                      style={{ marginTop: "-20px" }}
                      message={errors.cellphone_number}
                      type="error"
                      showIcon
                    />
                  )}
                </Col>
              </Row>
              <Row className="row-form">
                <Col xs={24} md={24} xl={24}>
                  <Form.Item label="Correo electrónico">
                    <Input
                      name="email"
                      placeholder="Correo electrónico"
                      onChange={handleChange}
                      value={data.email}
                    />
                  </Form.Item>
                  {errors?.email && (
                    <Alert
                      style={{ marginTop: "-20px" }}
                      message={errors.email}
                      type="error"
                      showIcon
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="section-form-title">¿Asignar contraseña?</p>
                </Col>
              </Row>
              <Row className="row-form">
                <Col xs={24} md={11} xl={11}>
                  <Form.Item label="Contraseña">
                    <Input
                      name="password1"
                      placeholder="Contraseña"
                      onChange={handleChange}
                      value={data.password1}
                      type="password"
                    />
                  </Form.Item>
                  {errors?.password1 && (
                    <Alert
                      style={{ marginTop: "-20px" }}
                      message={errors.password1}
                      type="error"
                      showIcon
                    />
                  )}
                </Col>
                <Col xs={24} md={11} xl={11}>
                  <Form.Item label="Repetir contraseña">
                    <Input
                      name="password2"
                      placeholder="Repetir contraseña"
                      onChange={handleChange}
                      value={data.password2}
                      type="password"
                    />
                  </Form.Item>
                  {errors?.password2 && (
                    <Alert
                      style={{ marginTop: "-20px" }}
                      message={errors.password2}
                      type="error"
                      showIcon
                    />
                  )}
                </Col>
              </Row>
              <GoogleReCaptchaProvider
                reCaptchaKey={String(process.env.REACT_APP_KEY_RECAPTCHA)}
              >
                <GoogleReCaptcha
                  onVerify={handleVerify}
                  refreshReCaptcha={refreshReCaptcha}
                />
              </GoogleReCaptchaProvider>
              {errors?.recaptcha && (
                <Row className="row-form">
                  <Col xs={24} md={24} xl={24}>
                    <Alert
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                      message={errors.recaptcha}
                      type="error"
                      showIcon
                    />
                  </Col>
                </Row>
              )}
              <Form.Item style={{ marginBottom: "0px" }}>
                <Button block={true} type="primary" onClick={handleClick}>
                  REGISTRARME
                </Button>
                <div style={styles.footer}>
                  <Link to="/type-register">Volver</Link>
                </div>
              </Form.Item>
            </Form>
          </div>
        </section>
      </div>
    </>
  );
}

export default Register;
