import { useState, useEffect } from "react";
import { Button, Col, Flex, Row } from "antd";
import { PlusOutlined, EyeOutlined, SettingOutlined } from "@ant-design/icons";
import { severalStore, userStore } from "../../../../stores";
import {
  getLivestockProductsByAssociativeForm,
  createLivestockProduct,
  updateLivestockProduct,
} from "../../../../servicesHTTP";
import { Loading, Table } from "../../../../components";
import CreateUpdate from "./CreateUpdate";
import { enableButtonSave, messageSweetalert } from "../../../../utilities";
import { applicationModules } from "../../../../constants";
import Process from "../processes/Home";

const initialData = {
  id: 0,
  production_oriented_marketing: 2,
  area_used_development_economic_activity_hectares: "",
  use_ownership_land_economic_activity_id: 0,
  species: "",
  purpose_id: [],
  productive_inventory: "",
  production_volume: "",
  operating_health_permits: 2,
  inputs_id: [],
  technification: "",
  people_required_crop_cycle: "",
  type_labor_contract_id: 0,
  type_machinery_require_production: "",
  machinery_property_id: 0,
  production_cost_cycle: "",
  expenditure_cycle: "",
  production_cost_final: "",
  how_you_set_selling_price: "",
  how_much_expect_produce_this_cycle: "",
  associative_form_id: 0,
  use_ownership_land_economic_activity: null,
  type_labor_contract: null,
  machinery_property: null,
  production_cycles_duration: [],
  sales_price: [],
  unit_measurement_expect_produce_this_cycle_id: 0,
  unit_measurement_expect_produce_this_cycle: null,
  new: true,
};

const validateData = (data: any) => {
  const errors: any = {};
  if (data.species === "") errors.species = "Ingrese Especie";

  return errors;
};

function Home() {
  const { setShowLoading, formSelected, getSeveralDataEconomicActivity }: any =
    severalStore();
  const { permissions } = userStore();
  const [records, setRecords] = useState([]);
  const [selected, setSelected] = useState({
    ...initialData,
    associative_form_id: formSelected.id,
  });
  const [modalOpenCreate, setModalOpenCreate] = useState<boolean>(false);
  const [modalOpenProcess, setModalOpenProcess] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [columns] = useState([
    {
      title: "Especie",
      dataIndex: "species",
      key: "species",
    },
    {
      title: "Costo producción de un lote/ animal o ciclo de producción",
      dataIndex: "production_cost_cycle",
      key: "production_cost_cycle",
    },
    {
      title: "Gasto por cada lote o ciclo producido",
      dataIndex: "expenditure_cycle",
      key: "expenditure_cycle",
    },
    {
      title: "Acción",
      dataIndex: "acciones",
      key: "acciones",
      width: 200,
      render: (text: any, record: any, index: any) => {
        return (
          <Flex style={{ marginBottom: 10 }}>
            <Button
              type="link"
              size="large"
              icon={<EyeOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => {
                setSelected({ ...record, new: false });
                setModalOpenCreate(true);
              }}
            >
              {enableButtonSave(
                applicationModules,
                permissions,
                "economicActivity"
              )
                ? "Editar "
                : "Ver "}
              producto
            </Button>
            <Button
              type="link"
              size="large"
              icon={<SettingOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => {
                setSelected({ ...record, new: false });
                setModalOpenProcess(true);
              }}
            >
              Procesos
            </Button>
          </Flex>
        );
      },
    },
  ]);

  const getProducts = async (formSelected: any) => {
    if (formSelected.id !== 0) {
      const result = await getLivestockProductsByAssociativeForm(
        formSelected.id
      );
      if (result.status === "ok") {
        setRecords(result.data);
      }
    }
  };

  useEffect(() => {
    getSeveralDataEconomicActivity();
  }, [getSeveralDataEconomicActivity]);

  useEffect(() => {
    (async () => {
      setShowLoading(true);
      await getProducts(formSelected);
      setShowLoading(false);
    })();
  }, [formSelected, setShowLoading]);

  useEffect(() => {
    if (Object.keys(selected).length > 0) {
      const pre: any = records.find((x: any) => x.id === selected.id);
      if (pre) {
        setSelected(pre);
      }
    }
    // eslint-disable-next-line
  }, [records]);

  const orderData = (data: any) => {
    const newData = {
      ...data,
      inputs_id: data.inputs_id ? data.inputs_id.join() : null,
      purpose_id: data.purpose_id ? data.purpose_id.join() : null,
    };

    return newData;
  };

  const saveRecord = async (data: any) => {
    setErrors({});
    const errors = validateData(data);
    data = orderData(data);
    if (Object.keys(errors).length <= 0) {
      if (data.new) {
        setShowLoading(true);
        const result = await createLivestockProduct(data);
        if (result.status === "ok") {
          messageSweetalert("Terminado", <p>Datos guardados.</p>);
          await getProducts(formSelected);
          setModalOpenCreate(false);
          setSelected({
            ...initialData,
            associative_form_id: formSelected.id,
          });
        } else {
          messageSweetalert(
            "Terminado",
            <p>No se guardaron los datos.</p>,
            "error"
          );
        }
        setShowLoading(false);
      } else {
        setShowLoading(true);
        const result = await updateLivestockProduct(data);
        if (result.status === "ok") {
          messageSweetalert("Terminado", <p>Datos guardados.</p>);
          await getProducts(formSelected);
        } else {
          messageSweetalert(
            "Terminado",
            <p>No se guardaron los datos.</p>,
            "error"
          );
        }
        setShowLoading(false);
      }
      return;
    }

    setErrors(errors);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Flex style={{ marginBottom: 10 }}>
            {enableButtonSave(
              applicationModules,
              permissions,
              "economicActivity"
            ) && (
              <Button
                type="primary"
                size="large"
                icon={<PlusOutlined />}
                style={{ marginRight: 10 }}
                onClick={() => {
                  setSelected({
                    ...initialData,
                    associative_form_id: formSelected.id,
                  });
                  setModalOpenCreate(true);
                }}
              >
                Agregar producto
              </Button>
            )}
          </Flex>
          <Table
            dataSource={records || []}
            columns={columns}
            bordered
            scroll={{ x: "1000" }}
            selection={false}
          />
        </Col>
      </Row>

      {modalOpenCreate && (
        <CreateUpdate
          open={modalOpenCreate}
          handleClose={setModalOpenCreate}
          data={{ ...selected }}
          saveRecord={saveRecord}
          errors={errors}
        />
      )}

      {modalOpenProcess && (
        <Process
          open={modalOpenProcess}
          handleClose={setModalOpenProcess}
          product={{ ...selected }}
          productType={"2"}
        />
      )}
      <Loading />
    </>
  );
}

export default Home;
