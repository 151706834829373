import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ChartData,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

interface IProps {
  titleText: string;
  labels: string[];
  datasets: any;
}

function RadarChart({ titleText = "", labels = [], datasets }: IProps) {
  const [radarChartOptions] = useState<any>({
    responsive: true,
    scales: {
      r: {
        min: 0,
        max: 4,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      title: {
        display: false,
        text: titleText,
        position: "bottom",
      },
    },
  });
  const [radarChartData, setRadarChartData] = useState<ChartData<"radar">>({
    labels: labels,
    datasets: datasets,
  });

  useEffect(() => {
    setRadarChartData((prev: ChartData<"radar">) => ({ ...prev, datasets }));
  }, [datasets]);
  return <Radar data={radarChartData} options={radarChartOptions} />;
}

export default RadarChart;
