import React from "react";
import { Accordion } from "../../../../components";
import EnvironmentalGoodPractices from "./EnvironmentalGoodPractices";
import ProcessCharacterization from "./ProcessCharacterization";

function ProcessesAccordion({ handleChange, record }: any) {
  return (
    <Accordion
      size={"large"}
      items={[
        {
          key: "1",
          label: "Ambiental y buenas prácticas",
          children: (
            <EnvironmentalGoodPractices
              handleChange={handleChange}
              record={record}
            />
          ),
        },
        {
          key: "2",
          label: "Caracterización de proceso",
          children: (
            <ProcessCharacterization
              handleChange={handleChange}
              record={record}
            />
          ),
        },
      ]}
    />
  );
}

export default ProcessesAccordion;
